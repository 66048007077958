<template>
  <div style="border-radius: 8px" class="row">
    <div class="border">
      <div class="card ps-2">
        <div class="col-md-2 col-12">
          <div class="mb-1">
            <button onclick="window.print()" class="btn btn-primary mt-1">Print</button>
          </div>
        </div>
      </div>
      <div class="bg-white p-2 row">
        <div class="border border-2 card-width rounded-3" id="section-to-print">
          <div class="border border-1 p-25 row">
            <div class="col-3 my-auto ps-50"><img :src="company.logo" :alt="company.name" class="w-44"></div>
            <div class="col-9 my-auto text-black p-0 main-text">{{ company.name }}</div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="my-25">
                <div class="text-blue">Name</div>
                <h4 class="text-black main-text">{{ cardInfo.full_name }}</h4>
              </div>
              <div class="my-25">
                <div class="text-blue">Age & Sex</div>
                <h4 class="text-black main-text">
                  {{ cardInfo.birthday ? (calculateAge(cardInfo.birthday) + ', ' + capitalize(cardInfo.gender)) : '' }}
                </h4>
              </div>
              <div class="my-25">
                <div class="text-blue">Contact no</div>
                <h4 class="text-black main-text">{{ getPatientDetails(cardInfo, 'mobile_no') }}</h4>
              </div>
            </div>
            <div class="col-6 my-auto">
              <div class="font-medium-5 my-25 text-black text-center">
                <div v-html="qrcode"></div>
               <div class="main-text mt-25">ID: {{ cardInfo.serial_no }}</div>
              </div>
            </div>
          </div>
          <div class="border-top row">
            <div class="col-12 issue-text mt-25 text-black text-center">
              Issue Date & Time: {{ formatDate(new Date()) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import handleCompanyContact from "@/services/modules/companyContact";
import handleBarcode from "@/services/modules/barcode";
import handleHospital from "@/services/modules/hospital";
import {useStore} from "vuex";

const {fetchContactDetails} = handleCompanyContact();
const {calculateAge} = handleHospital();
const {fetchBarcode} = handleBarcode();
const route = useRoute();
const store = useStore();

let companyId = computed(() => route.params.companyId);
let company = computed(() => store.getters['company/getCompany']);
let qrcode = ref(null);

let cardInfo = ref({
  quantity: '',
  birthday: '',
  gender: '',
  full_name: '',
  serial_no: '',
});

const getContactDetails = async (patientId, type = 'activities') => {
  try {
    const res = await fetchContactDetails(type, patientId, '?company_id=' + companyId.value);
    if (!res.status) {
      return cardInfo.value = {}
    }
    cardInfo.value = res.data.contact_profile;
    getBarCode(cardInfo.value.serial_no)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const getBarCode = (serial_no) => {
  fetchBarcode(`?code=${serial_no}&qrcode=${serial_no}&qrcode_width=3&qrcode_height=3`).then((res) => {
    qrcode.value = res.qrcode;
  })
}

const getPatientDetails = (patient, fieldName) => {
  if (patient.profile_items) {
    const field = patient.profile_items.find(info => info.field === fieldName)
    return (field && field.value) ?? '';
  }
  return "";
}

const capitalize = (text) => {
  if (!text || typeof text !== 'string') {
    return '';
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
}

const formatDate = (date) => {
  var formattedDate = date.toISOString().split('T')[0];
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var amOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
  return formatToDdMmYy(formattedDate) + ' ' + formattedTime
}

const formatToDdMmYy = (date) => {
  var dat = date.split('-');
  return `${dat[2]}-${dat[1]}-${dat[0]}`;
}

onMounted(async () => {
  await getContactDetails(route.params.patientId)
})

</script>

<style>
.issue-text{
  font-size: 11px;
}
.text-blue {
  color: blue;
  font-size: 11px;
}
.main-text{
  font-size: 14px;
}
.card-width {
  width: 3.3in;
  height: 2.1in;
}
@media print {
  body {
    visibility: hidden;
  }

  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
  @page {
    margin: 0;
  }
}
@page {
  size: 2.2in 1.4in;
}
</style>
